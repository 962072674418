<!-- 成人答题  /adultEducation/answer -->
<template>
  <div style="height: 100%">
    <!-- <div style="height: 30px"></div> -->
    <el-card class="box-card">
      <div class="content">
        <div class="left">
          <div class="title">
            <div>做题列表</div>
          </div>
          <!-- <div style="height: 40%">
            <el-scrollbar class="scrollbar"> -->
          <!-- <el-card>
             
                <ul>
                  <li class="des_n">
                    变式名称：
                    <i v-html="caseName"></i>
                  </li>
                  <li v-for="(item, describeDOIndex) in describeDO" :key="describeDOIndex">
                    {{ descriptionType[item.type] }}
                    <div class="des_p">
                      <i v-html="item.nameDescribe"></i>
                    </div>
                  </li>
                </ul>
              </el-card> -->
          <!-- </el-scrollbar>
          </div> -->
          <div style="height: 100%" >
            <el-scrollbar class="scrollbar scrollbar-a"   ref="myScrollbar">
              <el-card>
                <!-- <div v-html="caseTopic"></div> -->
                <ul>
                  <li class="des_n">
                    变式名称：
                    <i v-html="caseName"></i>
                  </li>
                  <li
                    v-for="(item, describeDOIndex) in describeDO"
                    :key="describeDOIndex"
                  >
                    {{ descriptionType[item.type] }}
                    <div class="des_p">
                      <i v-html="item.nameDescribe"></i>
                    </div>
                  </li>
                </ul>
              </el-card>
              <el-card class="topic" v-for="(ite, i) in caseDO" :key="i">
                <p class="topic_serial">
                  <span>{{ ite.caseTopicSerial }}</span>
                  <span v-if="ite.caseType == 1">【单选题】</span>
                  <span v-if="ite.caseType == 2">【多选题】</span>
                </p>
                <el-scrollbar class="scrollbar">
                  <p v-html="ite.caseTopic"></p>
                  <div class="topic_con">
                    <el-radio-group
                      v-model="ite.userAnswer"
                      style="width: 100%"
                      v-if="ite.caseType == 1"
                      @change="verify"
                    >
                      <div
                        style="width: 100%"
                        :class="
                          ite.userAnswer == item.sn
                            ? 'option1 option'
                            : 'option'
                        "
                        v-for="(item, index) in ite.answerEntities"
                        :key="index"
                      >
                        <el-radio :label="item.sn" style="padding-top: 15px">
                          <div style="display: flex">
                            <span class="sn">{{ item.sn }}：</span>
                            <span
                              v-html="item.choiceContent"
                              class="span_p"
                            ></span>
                          </div>
                        </el-radio>
                      </div>
                    </el-radio-group>
                    <el-checkbox-group
                      v-model="ite.userAnswer"
                      v-if="ite.caseType == 2"
                      style="width: 100%"
                      @change="verify"
                    >
                      <!-- :class="ite.userAnswer[index] == item.sn?'option1 option':'option'" -->
                      <div
                        style="width: 100%"
                        :class="
                          find_item(ite, item) ? 'option1 option' : 'option'
                        "
                        v-for="(item, index) in ite.answerEntities"
                        :key="index"
                      >
                        <el-checkbox :label="item.sn" style="padding-top: 15px">
                          <div style="display: flex">
                            <span class="sn">{{ item.sn }}：</span>
                            <span
                              v-html="item.choiceContent"
                              class="span_p"
                            ></span>
                          </div>
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </el-scrollbar>
              </el-card>
            </el-scrollbar>
          </div>

          <el-button type="primary" class="Nextquestion" v-if="state == 2">
            <div>下一题</div>
          </el-button>
          <el-button
            type="primary"
            class="Nextquestion"
            @click="submit"
            v-if="state == 1"
            :disabled="disabled"
          >
            <div>提交</div>
          </el-button>
        </div>
        <div class="right">
          <div class="title" style="margin-left: 40px">
            <div>{{ TerrName }}</div>
          </div>
          <el-scrollbar class="scrollbar scrollbar-a">
            <el-tree
              class="tree"
              :data="knowledgeEntityList"
              :props="defaultProps"
              @node-click="handleNodeClick"
              @node-collapse="nodeCollapse"
              @node-expand="expand()"
            >
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <p>{{ node.label }}</p>
                <div
                  v-if="data.descriptionsDOS"
                  class="description"
                  @click="onTree(node, data)"
                >
                  <p>{{ data.descTitle }}</p>
                  <!-- <div
                    v-for="(item, index) in data.descriptionsDOS"
                    :key="index"
                  >
                    <span
                      v-html="item.descriptions"
                      class="custom-tree-node-content"
                    ></span>
                  </div>-->
                </div>
              </div>
            </el-tree>
          </el-scrollbar>

          <el-button
            class="Nextquestion exit"
            style="margin-left: 40px"
            @click="exit"
          >
            <div>退出</div>
          </el-button>
        </div>
      </div>
    </el-card>

    <el-dialog class="terr-dialog" :title="TerrName" :visible.sync="dialogVisible" style="overflow: hidden">
      <div v-for="(m, i) in TerrCon" :key="i">
        <span v-html="m.descriptions"></span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onDialogVisible(2)">关 闭</el-button>
        <el-button type="primary" @click="onDialogVisible(1)"
          >此为标准答案</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  themeStudentDetails,
  themeStudentSubmit,
  themeStudentGetTheme,
} from "../../../api/api.js";
export default {
  data() {
    return {
      time: null,
      TerrName: "溯源《找标准答案》",
      TerrID: {
        id: "",
        onID: "",
      },
      TerrTitle: null,
      TerrCon: [],
      index: 0,
      defaultProps: {
        children: "knowledgeEntityDescDOS",
        label: "title",
      },
      descriptionType: this.parameter.descriptionType(),
      data: {}, //全部数据
      caseDO: [],
      knowledgeEntityList: [],
      describeDO: [], //描述
      recordOnTree: [],
      recordOnOption: [],
      caseName: null,
      state: "1", //显示判断 1提交  2下一题
      checkList: [],
      activityId: null,
      disabled: true,
      obj: {},
      dialogVisible: false, //弹窗
      startTree: {},
      endTree: {},
    };
  },

  components: {},
  mounted() {
    this.init();
  },
  methods: {
    find_item(ite, item) {
      let val = ite.userAnswer.find((find_item) => {
        return find_item == item.sn;
      });
      return val;
    },
    init() {
      this.obj = JSON.parse(this.$route.query.data);
      if (this.obj.index !== undefined) {
        this.index = this.obj.index;
      }
      this.activityId = this.obj.activityId;
      this.themeStudentDetails();
    },

    //获取学生活动详情
    themeStudentDetails() {
      themeStudentGetTheme({ id: this.obj.themeId }).then((res) => {
        this.knowledgeEntityList = res.data.knowledgeEntityList;
      });
      themeStudentDetails({ id: this.activityId }).then((res) => {
        res.data.themeList.map((item) => {
          item.themeCaseDO.caseDO.map((caseDO) => {
            if (caseDO.caseType == 2) {
              caseDO.userAnswer = [];
            } else {
              caseDO.userAnswer = "";
            }
          });
        });
        this.data = res.data.themeList;
        // this.data.map((item, index) => {
        //   if (this.obj.index == undefined) {
        //     if (item.themeCaseDO.themeCaseNameEntity.isRoW == 1) {
        //       // this.data.splice(index, 1);
        //       this.index += 1;
        //     }
        //   }
        // });
        if (this.obj.index == undefined) {
          var find = this.data.filter(function (item) {
            return item.themeCaseDO.themeCaseNameEntity.isRoW == 3;
          });
          if (find.length > 0) {
            this.data = find;
          }

          this.data.map((item, index) => {
            if (index == this.index) {
              if (item.themeCaseDO.themeCaseNameEntity.isRoW == 1) {
                this.index += 1;
              } else {
                return;
              }
            }
          });
        }
        this.describeDO = this.data[this.index].themeCaseDO.describeDO;
        this.caseDO = this.data[this.index].themeCaseDO.caseDO;
        this.caseName =
          this.data[this.index].themeCaseDO.themeCaseNameEntity.caseName;
      });
    },
    //验证是否全部答完
    verify(val) {
      var num = 0;
      this.caseDO.map((item) => {
        if (
          item.userAnswer == "" ||
          item.userAnswer == [] ||
          item.userAnswer == undefined
        ) {
          num++;
        }
      });
      if (num > 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    //提交变式
    themeStudentSubmit(obj) {
      themeStudentSubmit(obj).then((res) => {
        if (res.message == "请求成功") {
          this.recordOnTree = []; //记录完清空点击树事件
          this.TerrID.onID = "";
          this.data[
            this.index
          ].themeCaseDO.themeCaseNameEntity.userCaseCount += 1;
          this.index += 1;
          this.data.map((item, index) => {
            if (index == this.index) {
              if (item.themeCaseDO.themeCaseNameEntity.isRoW == 1) {
                this.index += 1;
              } else {
                return;
              }
            }
          });

          if (this.data.length <= this.index) {
            this.$message({
              message: "全部答题成功,自动退出",
              type: "success",
            });
            this.$router.go(-1);
          } else {
           this.$refs.myScrollbar.wrap.scrollTop = 0

            this.$message({
              message: "答题成功,自动进入下一题",
              type: "success",
            });
            this.describeDO = this.data[this.index].themeCaseDO.describeDO;
            this.caseDO = this.data[this.index].themeCaseDO.caseDO;
            this.caseName =
              this.data[this.index].themeCaseDO.themeCaseNameEntity.caseName;
          }
        } else {
          this.disabled = false;
          this.$message.error(res.message);
        }
      });
    },
    //提交
    submit() {
      if (this.TerrID.onID == "") {
        this.$confirm("你当前尚未从溯源中选择标准答案, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.disabled = true;
            var topicMessage = [];
            this.caseDO.map((item) => {
              var topicobj = {};
              if (item.caseType == 2) {
                var answer = String(item.userAnswer);
                topicobj = { topicId: item.id, answer: answer };
                topicMessage.push(topicobj);
              } else {
                topicobj = { topicId: item.id, answer: item.userAnswer };
                topicMessage.push(topicobj);
              }
            });
            var presentCount =
              this.data[this.index].themeCaseDO.themeCaseNameEntity
                .userCaseCount;
            var obj = {
              activityId: this.activityId,
              caseId: this.data[this.index].themeCaseDO.themeCaseNameEntity.id,
              presentCount: ++presentCount + "",
              isLastCase: "2",
              topicMessage: topicMessage,
              userLogEntities: this.recordOnTree,
              themeId: this.obj.themeId,
              correctKnowledgeId: this.TerrID.onID,
            };
            this.themeStudentSubmit(obj);
          })
          .catch(() => {
            this.disabled = false;
            this.$message({
              type: "info",
              message: "已取消提交",
            });
            return;
          });
      } else {
        this.disabled = true;
        var topicMessage = [];
        this.caseDO.map((item) => {
          var topicobj = {};
          if (item.caseType == 2) {
            var answer = String(item.userAnswer);
            topicobj = { topicId: item.id, answer: answer };
            topicMessage.push(topicobj);
          } else {
            topicobj = { topicId: item.id, answer: item.userAnswer };
            topicMessage.push(topicobj);
          }
        });
        var presentCount =
          this.data[this.index].themeCaseDO.themeCaseNameEntity.userCaseCount;
        var obj = {
          activityId: this.activityId,
          caseId: this.data[this.index].themeCaseDO.themeCaseNameEntity.id,
          presentCount: ++presentCount + "",
          isLastCase: "2",
          topicMessage: topicMessage,
          userLogEntities: this.recordOnTree,
          themeId: this.obj.themeId,
          correctKnowledgeId: this.TerrID.onID,
        };
        this.themeStudentSubmit(obj);
      }
    },

    //点击树
    onTree(node, data) {
      this.time = Date.parse(new Date());
      this.TerrTitle = data.descTitle; //保留的title
      this.TerrCon = data.descriptionsDOS; //保留的title
      this.TerrID.id = data.id;
      this.startTree = this.recordTree.recordTree(
        data.title || data.descTitle,
        this.time,
        "查看了",
        1
      );

      this.dialogVisible = true;
    },
    //保存
    onDialogVisible(type) {
      //确定
      if (type == 1) {
        this.endTree = this.recordTree.recordTree(
          this.TerrTitle,
          this.time,
          "选择了",
          2
        );
        this.recordOnTree.push(Object.assign(this.endTree, this.startTree));
        this.time =
          this.recordTree.recordTree(this.TerrTitle, this.time).second * 1000;
        this.TerrID.onID = this.TerrID.id;
        this.dialogVisible = false;
      } else {
        //不是
        this.endTree = this.recordTree.recordTree(
          this.TerrTitle,
          this.time,
          "关闭了",
          2
        );
        this.recordOnTree.push(Object.assign(this.endTree, this.startTree));
        this.time =
          this.recordTree.recordTree(this.TerrTitle, this.time).second * 1000;
        this.dialogVisible = false;
      }
    },
    selectSearchItem(data) {
      console.log("展开", data);
    },
    nodeCollapse(data) {
      console.log("收起", data);
    },
    handleNodeClick(data) {
      // this.recordOnTree.push(
      //   this.recordTree.recordTree(data.title || data.descTitle, this.time)
      // );
      // this.time =
      //   this.recordTree.recordTree(data.sontitle, this.time).second * 1000;
    },
    expand(data) {
      // console.log(data);
    },
    exit() {
      this.$router.go(-1); //返回上一层
    },
    beforeunloadHandler(e) {
      console.log("关闭窗口之后");
    },
  },
};
</script>
<style lang='less' scoped>
@import "./style/index.less";
.option1 {
  background-color: #d8e8ff !important;
}
/****************************************************add css**************************************************************** */
.des_p{
  overflow: hidden;
}
.des_p/deep/ table tr td{
  background: #d8e8ff !important;
}
.des_p/deep/ table tr td p{
  background: #d8e8ff !important;
}
.terr-dialog/deep/ .el-dialog .el-dialog__body{
  height: auto;
  max-height: 550px;
  overflow: auto;
}
</style>
